import { Footer } from "../Components/Footer";
import React, { useEffect, useState } from "react";
import "../Assets/CSS/Challenges.scss";
import "../Assets/CSS/Challenges.responsive.scss";
import { ShuffleChallenge, ShuffleChallengers } from "../API/api";
import { axiosGet } from "../Utility/httpClient";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import * as moment from "moment";
import { endDate, numberWithCommas } from "../Utility/utils";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ChallengeLB } from "../Components/ChallengeLB";
import { PrevMonthChallengesLB } from "../Constants/PrevLeaderboard";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  "&& .Mui-selected": {
    backgroundColor: "pink",
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      color: "#acacac",
      background: "#121418",
    },
  },
};

type BetSizeOption = {
  id: number;
  name: string;
  count: number;
};

const variants: Array<BetSizeOption> = [
  {
    id: 8,
    name: "$1",
    count: 1,
  },
  {
    id: 7,
    name: "$.70",
    count: 0.7,
  },
  {
    id: 6,
    name: "$.60",
    count: 0.6,
  },
  {
    id: 5,
    name: "$.50",
    count: 0.5,
  },
  {
    id: 4,
    name: "$.40",
    count: 0.4,
  },
  {
    id: 3,
    name: "$.30",
    count: 0.3,
  },
  {
    id: 2,
    name: "$.20",
    count: 0.2,
  },
  {
    id: 1,
    name: "$.10",
    count: 0.1,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  gridList: {
    width: "100%",
    height: "auto",
  },
  card: {
    maxWidth: 250,
    height: "470px",
    margin: "16px",
    paddingTop: "15px",
    border: "1px solid #2a2e38",
    borderRadius: "30px !important",
    boxShadow: "none",
    background: "#2f333a !important",
  },
  cardMedia: {
    height: "350px",
    width: "250px",
    padding: "0",
    marginTop: "-20px",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
  },
  cardContent: {
    // background: 'linear-gradient(180deg, #041128, #1e1826)',
    height: "200px",
    textAlign: "left",
    fontSize: "11px !important",
    borderBottomLeftRadius: "30px !important",
    borderBottomRightRadius: "30px !important",
    padding: "0 !important",
    color: "#cecece",
  },
  select: {
    "&:before": {
      borderColor: "white !important",
    },
    "&:after": {
      borderColor: "white !important",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white !important",
    },
    color: "white !important",
    background: "#1b1d27 !important",
  },

  icon: {
    fill: "white !important",
  },
  accordion: {
    background: "#2a2e38 !important",
    borderRadius: "10px",
    color: "white !important",
  },
}));

const getAllChallenges = async (): Promise<Array<ShuffleChallenge>> =>
  axiosGet(`/general/challenges`);
const getTimedChallenges = async (): Promise<Array<ShuffleChallenge>> =>
  axiosGet(`/general/timed-challenges`);
const getTopChallengers = async (): Promise<Array<ShuffleChallengers>> =>
  axiosGet(`/general/top-3-challengers`);

export default function Challenges() {
  const [tab, setTab] = useState<number>(0);
  const [data, setData] = useState<Array<ShuffleChallenge>>([]);
  const [timed, setTimed] = useState<Array<ShuffleChallenge>>([]);
  const [open, setLBOpen] = useState<boolean>(false);
  const [timedKey, setTimedKey] = useState<string>("timed-key-0");
  const [challengers, setChallengers] = useState<Array<ShuffleChallengers>>([]);
  const [variantName, setVariantName] = React.useState<BetSizeOption[]>([]);

  const timedClasses = useStyles();

  // setInterval(() => {
  //     setTimedKey('timed-key-' + new Date().getTime())
  // }, 1000)

  useEffect(() => {
    getAllChallenges().then((r) => {
      setData(r);
    });
    getTimedChallenges().then((r) => {
      setTimed(r);
    });
    getTopChallengers().then((r) => {
      setChallengers(tab === 1 ? PrevMonthChallengesLB : r);
    });
  }, [tab]);

  const customFormat = (date: any) => {
    //@ts-ignore
    let formattedDate = moment(date).format("MMMM Do YYYY, h:mm:ss a");
    return formattedDate;
  };

  const filteredTimed: any = [];
  timed.forEach((challenge) => {
    if (!challenge.winner) {
      filteredTimed.push(challenge);
    }
  });

  const ChallengesInfo = () => {
    const classes = useStyles();
    return (
      <>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<div />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            How does it work?
          </AccordionSummary>
          <AccordionDetails>
            <Typography gutterBottom align="left" component="div">
              The first one to hit the required multiplier wins the challenge.
            </Typography>
            <Typography gutterBottom align="left" component="div">
              All Challenges have to be completed on Shuffle.
            </Typography>
            <Typography gutterBottom align="left" component="div">
              Challenges are manually checked by Shuffle and are not completed
              instantly.
            </Typography>
            <Typography gutterBottom align="left" component="div">
              Only the first one to complete the Challenge will receive the
              Reward.
            </Typography>
            <br />
            <br />
            <Typography gutterBottom align="left" variant="h6" component="div">
              Make sure you're using <span>code BTCs</span> while hunting for
              challenges.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<div />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            What rewards are there?
          </AccordionSummary>
          <AccordionDetails>
            <Typography gutterBottom align="left" component="div">
              We have different type of rewards for the challenges:
              <ul>
                <li>Points</li>
                <li>Item Rewards</li>
                <li>Shuffle Tips</li>
              </ul>
            </Typography>
            <br />
            <br />
            <Typography gutterBottom align="left" component="div">
              To be able to receive Items & Points make sure that you verify
              your Shuffle account under Profile
            </Typography>
          </AccordionDetails>
        </Accordion>
        <br />
        <br />
        <br />
      </>
    );
  };

  const ChallengesFilter = (
    tab: number,
    setTab: any,
    variantName: BetSizeOption[],
    setVariantName: any
  ) => {
    const classes = useStyles();
    const handleChange = (event: any) => {
      const {
        target: { value },
      } = event;

      let duplicateRemoved: BetSizeOption[] = [];

      value.forEach((item: any) => {
        if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
          duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
        } else {
          console.log(item);
          duplicateRemoved.push(item);
        }
      });

      setVariantName(duplicateRemoved);
    };

    return (
      <div
        className="Challenge-list-header"
        style={{ display: "flex", justifyContent: "right", paddingRight: 100 }}
      >
        <div className="Bet-size-filter">
          <FormControl sx={{ m: 1, width: 250 }}>
            <InputLabel
              id="demo-multiple-checkbox-label"
              sx={{ color: "white" }}
            >
              Bet Size
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              className={classes.select}
              inputProps={{
                classes: {
                  icon: classes.icon,
                  root: classes.root,
                },
              }}
              id="demo-multiple-checkbox"
              multiple
              value={variantName}
              onChange={handleChange}
              sx={{
                color: "white",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#2a2e38",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#2a2e38",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#2a2e38",
                },
                ".MuiSvgIcon-root ": {
                  fill: "white !important",
                  color: "white !important",
                },
              }}
              input={<OutlinedInput sx={{ color: "white" }} label="Bet Size" />}
              renderValue={(selected) =>
                selected.map((x: any) => x.name).join(", ")
              }
              MenuProps={MenuProps}
            >
              {variants.map((variant) => (
                //@ts-ignore
                <MenuItem
                  key={variant.id}
                  value={variant}
                  sx={{
                    background: "#2d2f37 !important",
                  }}
                >
                  <Checkbox
                    color="error"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fill: "#7737e0",
                        fontSize: 30,
                      },
                    }}
                    checked={
                      variantName.findIndex(
                        (item: any) => item.id === variant.id
                      ) >= 0
                    }
                  />
                  <ListItemText primary={variant.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    );
  };

  const ChallengeList = (tab: number, variantName: BetSizeOption[]) => {
    const classes = useStyles();
    return (
      <Grid
        className={classes.gridList + " ChallengeGrid"}
        spacing={0}
        container
      >
        {data
          .filter((tile) => !tile.timed)
          .filter((tile) => {
            const activeFilter = !tile.winner;
            let sizeFilter = variantName.length == 0;
            if (!sizeFilter) {
              variantName.map((variant) => {
                if (tile.amount == variant.count) {
                  sizeFilter = true;
                }
              });
            }
            return activeFilter && sizeFilter;
          })
          .map((tile) => {
            return (
              <Card
                sx={{ minWidth: 250 }}
                className={classes.card + " ChallengeCard"}
              >
                <CardMedia
                  component="img"
                  alt={tile.name}
                  className={classes.cardMedia}
                  image={tile.image}
                />
                <CardContent className={classes.cardContent}>
                  <div className="Challenge-Info">
                    <ul>
                      <li>
                        <img src="https://btcs.gg/challenges-img/target-icon-challenge.svg" />
                      </li>
                      <li>{tile.multiplier.toLocaleString("en-US")}x</li>
                    </ul>
                    <ul>
                      <li>
                        <img src="https://btcs.gg/challenges-img/multi-challenges-icon.svg" />
                      </li>
                      <li>${numberWithCommas(tile.amount)}</li>
                    </ul>
                    <ul>
                      <li>
                        <img src="https://btcs.gg/challenges-img/prize-icon.svg" />
                      </li>
                      <li>${tile.reward.toLocaleString("en-US")}</li>
                    </ul>
                  </div>

                  <div className="Challenge-Play">
                    <button>Play Now</button>
                  </div>
                </CardContent>
              </Card>
            );
          })}
      </Grid>
    );
  };

  return (
    <>
      <div className="App-contents Challenges VIP">
        <div className="Landing-content">
          <br />
          <br />
          <div className="Landing-heading">
            <h2 className="Main-heading Main-heading-larger">Challenges</h2>
            <div className="Heading-divider" />
            <p className="Main-subheading">
              Wager to automatically claim these challenges and compete on our
              monthly{" "}
            </p>
            <div style={{ display: "flex" }}>
              <h3
                className="Main-heading"
                style={{ fontSize: "2.9vw", margin: "0", padding: "0" }}
              >
                <span style={{ fontFamily: "Helvetica", color: "white" }}>
                  $
                </span>
                2,000 leaderboard
              </h3>
            </div>
          </div>
          <div
            className="Active-filter"
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 40,
            }}
          >
            <div
              className={tab === 0 ? `Active` : ""}
              onClick={() => setTab(0)}
            >
              Current Month
            </div>
            <div
              className={tab === 1 ? `Active` : ""}
              onClick={() => setTab(1)}
            >
              Previous Month
            </div>
          </div>

          <div className="Challengers">
            <div className="Challenger">
              <div className="Challenger-Image">
                <img
                  src={challengers[0]?.image ?? "https://btcs.gg/newcoin.svg"}
                />
              </div>
              <div className="Challenger-content Gold">
                <div className="Challenger-Name">
                  <h3>{challengers[0]?.username.substring(0, 3) ?? "-"}</h3>
                </div>
                <div className="Challenger-Values">
                  <ul>
                    <li>Completed</li>
                    <li>
                      <span>
                        {challengers[0]?.completed.toLocaleString("en-US") ??
                          "-"}
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>Earned</li>
                    <li>
                      <span>
                        ${challengers[0]?.earned.toLocaleString("en-US") ?? "-"}
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>Prize</li>
                    <li>
                      <span>{challengers[0]?.prize ?? "-"}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="Challenger">
              <div className="Challenger-Image">
                <img
                  src={challengers[1]?.image ?? "https://btcs.gg/newcoin.svg"}
                />
              </div>
              <div className="Challenger-content Silver">
                <div className="Challenger-Name">
                  <h3>{challengers[1]?.username.substring(0, 3) ?? "-"}</h3>
                </div>
                <div className="Challenger-Values">
                  <ul>
                    <li>Completed</li>
                    <li>
                      <span>
                        {challengers[1]?.completed.toLocaleString("en-US") ??
                          "-"}
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>Earned</li>
                    <li>
                      <span>
                        ${challengers[1]?.earned.toLocaleString("en-US") ?? "-"}
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>Prize</li>
                    <li>
                      <span>{challengers[1]?.prize ?? "-"}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="Challenger">
              <div className="Challenger-Image">
                <img
                  src={challengers[2]?.image ?? "https://btcs.gg/newcoin.svg"}
                />
              </div>
              <div className="Challenger-content Bronze">
                <div className="Challenger-Name">
                  <h3>{challengers[2]?.username.substring(0, 3) ?? "-"}</h3>
                </div>
                <div className="Challenger-Values">
                  <ul>
                    <li>Completed</li>
                    <li>
                      <span>
                        {challengers[2]?.completed.toLocaleString("en-US") ??
                          "-"}
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>Earned</li>
                    <li>
                      <span>
                        ${challengers[2]?.earned.toLocaleString("en-US") ?? "-"}
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>Prize</li>
                    <li>
                      <span>{challengers[2]?.prize ?? "-"}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div               style={{
                display: "flex",
                justifyContent: "center",
              }}>
            {tab === 1 && (
                  <button
                    onClick={() => setLBOpen(!open)}
                    style={{ borderRadius: "5px" }}
                  >
                    {open ? "Show Less" : "Show More"}
                  </button>
                )}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {tab === 1 && open && <ChallengeLB rankings={PrevMonthChallengesLB} />}
            </div>

            <br />
            <br />
            <br />
            <br />
            <div className="Landing-heading">
              <h2 className="Main-heading">Timed Challenges</h2>
              <div className="Heading-divider" />
              {/*<p className="Main-subheading">Step into the spotlight with our leaderboard section! See who's*/}
              {/*    crushing it and join the fun as you aim for the top spot among fellow contenders.</p>*/}
            </div>
            <br />
            <br />

            <div className="Timed-challenges " key={timedKey}>
              <Grid
                className={timedClasses.gridList + " ChallengeGrid"}
                gap={2}
                container
              >
                {filteredTimed.map((challenge: any) => {
                  const end = endDate(challenge.end);
                  return (
                    <div className="TimedChallenge">
                      <div
                        className="Challenge-image"
                        style={{
                          backgroundImage: "url('" + challenge.image + "')",
                        }}
                      ></div>
                      <div className="Challenge-content">
                        <div className="Challenge-Title">
                          <span>{challenge.name}</span>
                        </div>
                        <div className="Challenge-Info">
                          <ul>
                            <li>
                              <img src="https://btcs.gg/challenges-img/target-icon-challenge.svg" />
                            </li>
                            <li>{challenge.multiplier}x</li>
                          </ul>
                          <ul>
                            <li>
                              <img src="https://btcs.gg/challenges-img/multi-challenges-icon.svg" />
                            </li>
                            <li>${challenge.amount}</li>
                          </ul>
                          <ul>
                            <li>
                              <img src="https://btcs.gg/challenges-img/prize-icon.svg" />
                            </li>
                            <li>${challenge.reward.toLocaleString("en-US")}</li>
                          </ul>
                        </div>
                        {/*<div className="Challenge-Time">*/}
                        {/*    <span>{challengeEndDate(challenge.end)}</span>*/}
                        {/*</div>*/}

                        <p
                          style={{
                            fontSize: "10px",
                            fontWeight: "700",
                            color: "white",
                          }}
                        >
                          ENDS IN
                        </p>
                        <div className="Timer Challenge-Timer">
                          <ol>
                            <li>
                              <p style={{ fontWeight: "700" }}>{end[0]}</p>
                            </li>
                            <li>
                              <p>Days</p>
                            </li>
                          </ol>
                          <div />
                          <ol>
                            <li>
                              <p style={{ fontWeight: "700" }}>{end[1]}</p>
                            </li>
                            <li>
                              <p>Hours</p>
                            </li>
                          </ol>
                          <div />
                          <ol>
                            <li>
                              <p style={{ fontWeight: "700" }}>{end[2]}</p>
                            </li>
                            <li>
                              <p>Mins</p>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Grid>
            </div>
          </div>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="Landing-heading">
            <h2 className="Main-heading">All Challenges</h2>
            <div className="Heading-divider" />
            {/*<p className="Main-subheading">Step into the spotlight with our leaderboard section! See who's*/}
            {/*    crushing it and join the fun as you aim for the top spot among fellow contenders.</p>*/}
          </div>
          <br />
          <br />

          <div className="Challenges-list">
            {ChallengesFilter(tab, setTab, variantName, setVariantName)}
            {ChallengeList(tab, variantName)}
          </div>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Footer />
        </div>
      </div>
    </>
  );
}
