export const PrevMonthChallengesLB = [
    {username: 'ChanChinda', completed: 61, earned: 4003.00, prize: '$4000', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Smokey1', completed: 36, earned: 2875.00, prize: '$2000', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Junkoishere', completed: 21, earned: 1867.00, prize: '$1000', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Reelss', completed: 21, earned: 1379.00, prize: '750', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'WeInThisBih', completed: 19, earned: 1426.00, prize: '600', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'lgd', completed: 15, earned: 1406.00, prize: '500', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Rekehh', completed: 15, earned: 768.00, prize: '400', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Fuqboy', completed: 13, earned: 995.00, prize: '300', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'ElderBTC', completed: 13, earned: 2770.00, prize: '250', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'huncho100', completed: 4, earned: 367.00, prize: '200', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'OopAStae', completed: 3, earned: 285.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'GGE', completed: 2, earned: 310.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: '999bredren', completed: 2, earned: 152.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'SuN1', completed: 2, earned: 105.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Jollyrogger', completed: 2, earned: 130.00, prize: '-', image: 'https://btcs.gg/newcoin.svg'},
]

export const PrevMonthWagerLB = [
    {username: '555', totalWagered: 18250215.04, prize: 7500, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Eld', totalWagered: 5178890.92, prize: 2500, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Oop', totalWagered: 2111264.10, prize: 1000, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Vol', totalWagered: 1990901.04, prize: 500, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Jun', totalWagered: 1434763.51, prize: 350, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Mtu', totalWagered: 1302107.25, prize: 275, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Gra', totalWagered: 1161290.57, prize: 250, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Jol', totalWagered: 635931.63, prize: 225, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'OGo', totalWagered: 469453.26, prize: 200, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Bru', totalWagered: 402688.38, prize: 175, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Hat', totalWagered: 347058.52, prize: 170, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Dic', totalWagered: 264288.32, prize: 165, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Smo', totalWagered: 199716.15, prize: 160, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Zac', totalWagered: 175794.59, prize: 155, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Ven', totalWagered: 168556.14, prize: 150, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Shr', totalWagered: 152288.30, prize: 145, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'SuN', totalWagered: 135479.93, prize: 140, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Jav', totalWagered: 131236.13, prize: 135, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Sat', totalWagered: 122287.79, prize: 130, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Mim', totalWagered: 119793.23, prize: 125, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Geo', totalWagered: 116428.68, prize: 120, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Lgd', totalWagered: 113835.87, prize: 115, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Cha', totalWagered: 107600.30, prize: 110, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Das', totalWagered: 106562.17, prize: 105, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'You', totalWagered: 93351.52, prize: 100, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Lon', totalWagered: 93075.19, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Rek', totalWagered: 87070.80, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'GGE', totalWagered: 66916.57, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Tok', totalWagered: 58562.57, prize: 0, image: 'https://btcs.gg/newcoin.svg'},
    {username: 'Ree', totalWagered: 58105.71, prize: 0, image: 'https://btcs.gg/newcoin.svg'}
]